import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import bottomImage from "./images/bottom_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                  <div className="topTitle">
                    <h1>{data.title}</h1>
                  </div>
                </div>

                <div className="section-main-description">
                <p>Eduro’s Core Values are: Wellness, Compassion, Experience, and Results. </p>
                <p>The Eduro Employee Fund supports current employees when they face extraordinary financial hardships. The Employee Fund embraces Eduro’s value of compassion and strives to provide support and aid in times of unforeseen financial crises that can be devasting to our employee’s overall well-being.  </p>
                <p>Eduro’s Employee Fund will provide support for a wide range of situations that colleagues might face:      </p>           
                <p>You face an unusual level of hardship or a personal crisis due to circumstances outside of your control.       </p>        
                <p>You need emergency support following a natural disaster               </p>
                <p>You are facing extraordinary costs associated with medical needs due to your health, well-being, or recovery.Your generosity, should you choose to donate to this fund, will support Eduro employees and their families when they need it most. </p>
                <p>Aside from the minimal credit card processing fees, all proceeds will go directly to our employees.  </p>
                <p>Donations and employee grants will be handled confidentially.  </p>
                <p>All donations are tax-deductible.</p>
                <p>Thank you for your generosity.</p>
                <p>Eduro Employee Fund</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions stripeAction={this.showModal} />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
                <br />
                ** If you would like to make a large donation with a different
                payment method, please reach out to Givinga at
                support@givinga.com
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
